const state = {
    // 监控列表
    hasAccount: false,
    wxUserInfo: JSON.parse(localStorage.getItem('wxUserInfo') as string) || {
        unionId: '',
        nickname: '',
        headimgurl: '',
        openid: ''
    },
    userInfo: {
        accessToken: '',
        avatar: '',
        company: '',
        mail: '',
        mobile: '',
        // eslint-disable-next-line
        nick_name: '',
        userId: ''
    },
    isWxBrower: false, // 是否在微信浏览器内
    wxTokenID: '',
    redirectUrl: '', // 回调链接地址
    accessToken: localStorage.getItem('accessToken') || '', // 账号登录对应accesstoken = userInfo.accessToken
    canPhoneBindWechat: false, // 用户输入的手机号是否可绑定当前微信 wxTokenID
    isPersonalBind: false, // 是否为个人信息页面绑定微信
    inputPhone: '', // 用户登陆时输入的手机号
    timer: null, // 定时器
    timePhone: '', // 获取验证码的手机
    timeAction: '', // 验证码的类型
    timeNumber: -1, // 获取验证码倒计时时间
    timeSmsCode: '' // 验证码
}

const getters = {}

const mutations = {
    updateLoginStatus(state: any, payload: any) {
        state.hasAccount = payload
    },
    updateWxUserInfo(state: any, payload: any) {
        state.wxUserInfo = payload
    },
    updateUserInfo(state: any, payload: any) {
        state.userInfo = payload
    },
    updateAccessToken(state: any, payload: any) {
        state.accessToken = payload
    },
    updateWxTokenID(state: any, payload: any) {
        state.wxTokenID = payload
    },
    updateTimeNumber(state: any, payload: any) {
        state.timeNumber = payload
    },
    updateTimePhone(state: any, payload: any) {
        state.timePhone = payload
    },
    updateTimeAction(state: any, payload: any) {
        state.timeAction = payload
    },
    updateTimeSmsCode(state: any, payload: any) {
        state.timeSmsCode = payload
    },
    updateIsWxBrower(state: any, payload: any) {
        state.isWxBrower = payload
    },
    updateIsPersonalBind(state: any, payload: any) {
        state.isPersonalBind = payload
    },
    updateCanPhoneBindWechat(state: any, payload: any) {
        state.canPhoneBindWechat = payload
    },
    updateTimer(state: any, payload: any) {
        state.timer = payload
    },
    updateRedirectUrl(state: any, payload: any) {
        state.redirectUrl = payload
    },
    updateInputPhone(state: any, payload: any) {
        state.inputPhone = payload
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
