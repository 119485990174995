let apiPath = '' // 数据API
let fileUrl = '' // 文件系统,上传upload，预览view，下载download
// const appid = 'wx21425cf28aad896f' // 云图表单
let appid = '' // 云图报告

if (process.env.NODE_ENV === 'development') {
    fileUrl = 'http://dfs.renttest.dev.cricwifi.com'

    apiPath = 'http://api.cricwifi.com/server/index.php?g=Web&c=Mock&o=simple&projectID=85&uri='

    // pcApiPath = 'https://yundev.cricyun.com/api/cric-data/v2'
} else if (process.env.NODE_ENV === 'test') {
    appid = 'wxf3e6c3dc74301215'
    apiPath = 'https://yuntest.cricyun.com/api/cric-data/v2'
} else if (process.env.NODE_ENV === 'production') {
    appid = 'wx21425cf28aad896f'
    apiPath = 'https://m.cricbigdata.com/api/cric-data/v2'
}

export default {
    apiPath,
    fileUrl,
    appid
}
