function parseUrl(url) {
    const result = {}
    url.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (result[k] = v))
    return result
}

function encodeUrl(url) {
    const reg = /#\/([^]+|)\?/
    if (reg.test(url)) {
        const [t1, t2] = url.split('#/')
        const [m1, m2] = t2.split('?')
        return encodeURIComponent(`${t1}?${m2}#/${m1}`)
    }
    return encodeURIComponent(url)
}

function getCodeByAppId(appId) {
    /* eslint-disable no-restricted-globals */
    const { code } = parseUrl(location.href)

    if (!code) {
        const url = encodeUrl(location.href)
        console.log(
            'oautho: ',
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=` +
                `${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        )
        location.href =
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=` +
            `${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    } else {
        const reg = /\?[^]+#\//
        if (reg.test(location.href)) {
            const [t1, t2] = location.href.split('?')
            const [m1, m2] = t2.split('#/')
            location.href = `${t1}#/${m2}?${m1}`
        }
    }
}

export default getCodeByAppId
