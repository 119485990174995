import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Util from '@/utils/index.js'
import getCodeByAppId from './getCodeByAppId.js'
import { Toast } from 'vant'
import { Dialog } from 'vant'
import { loadOpenId } from '@/api/getOpenId.js'
import API from '@/api/index.js'

Vue.use(Dialog)
Vue.use(Toast)

const { code, appId, appName, redirectUrl, token, noLogin } = Util.parseUrl(window.location.href) as any
store.commit('login/updateIsWxBrower', /MicroMessenger/i.test(navigator.userAgent))
redirectUrl && store.commit('login/updateRedirectUrl', redirectUrl)

function getCodeAndCodeLogin() {
    // 如果没有code 并且没有token的时候才进行获取code
    if (/MicroMessenger/i.test(navigator.userAgent) && !code && process.env.NODE_ENV !== 'development' && !token && !noLogin) {
        // 微信
        sessionStorage.getItem('code') && sessionStorage.removeItem('code')
        appId && getCodeByAppId(appId)
    }

    // 获取的code不是为了绑定微信时 才用来登录
    if (code && appName && !(store as any).state.login.isPersonalBind && !sessionStorage.getItem('code')) {
        loadOpenId(appName, code, redirectUrl)
    }
}

// 如果有回调链接并且本地存储有 accesstoken
const accessToken = localStorage.getItem('yt_h5_accesstoken')
async function checkToken() {
    const res = await API.checkTokenStatus({ accessToken })

    if (res && res.data && res.data.errorCode === 200) {
        localStorage.setItem('yt_h5_accesstoken', res.data.data.accessToken)
        if (redirectUrl.includes('?')) {
            window.location.href = `${decodeURIComponent(redirectUrl)}&token=${accessToken}`
        } else {
            window.location.href = `${decodeURIComponent(redirectUrl)}?token=${accessToken}`
        }
    } else {
        localStorage.setItem('yt_h5_accesstoken', '')
        getCodeAndCodeLogin()
    }
}

// 如果顶部链接无token（即非个人中心页），并且缓存有 accesstoken 和 回调链接

// 有noLogin时，标识不需要登录
if (noLogin) {
    localStorage.setItem('yt_h5_accesstoken', '')
    console.log('nologin')
    window.history.pushState({}, '', `?appId=${appId}&appName=${appName}&redirectUrl=${redirectUrl}#/login/phoneLogin`)
} else {
    if (!token && redirectUrl && accessToken && accessToken !== '' && accessToken !== undefined) {
        // 默认登录
        checkToken()
    } else {
        // 授权登录
        getCodeAndCodeLogin()
    }
}

// 如果有token 则标识进入个人信息界面
if (token) {
    store.commit('login/updateAccessToken', token)
    router.push({
        name: 'personalInfo'
    })
}

// open debug
if (window.location.href.indexOf('debug=true') !== -1) {
    require.ensure([], () => {
        const VConsole = require('vconsole')
        new VConsole()
    })
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
