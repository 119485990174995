import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location: any) {
    return (routerPush.call(this, location) as any).catch((error: any) => error)
}

VueRouter.prototype.replace = function(location: any) {
    return (routerPush.call(this, location) as any).catch((error: any) => error)
}

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/loading'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue'),
        children: [
            {
                path: '',
                redirect: 'phoneLogin'
            },
            {
                path: 'phoneLogin',
                name: 'phoneLogin',
                component: () => import('../views/login/components/phone-login/phoneLogin.vue')
            },
            {
                path: 'passwordLogin',
                name: 'passwordLogin',
                component: () => import('../views/login/components/password-login/passwordLogin.vue')
            },
            {
                path: 'findPassword',
                name: 'findPassword',
                component: () => import('../views/login/components/find-password/findPassword.vue')
            },
            {
                path: 'resetPassword',
                name: 'resetPassword',
                component: () => import('../views/login/components/reset-password/resetPassword.vue')
            },
            {
                path: 'bindPhone',
                name: 'bindPhone',
                component: () => import('../views/login/components/bind-phone/bindPhone.vue')
            },
            {
                path: 'bindTip',
                name: 'bindTip',
                component: () => import('../views/login/components/bind-tip/bindTip.vue')
            },
            {
                path: 'changePassword',
                name: 'changePassword',
                component: () => import('../views/login/components/change-password/changePassword.vue')
            },
            {
                path: 'lastPhone',
                name: 'lastPhone',
                component: () => import('../views/login/components/last-phone/lastPhone.vue')
            },
            {
                path: 'newPhone',
                name: 'newPhone',
                component: () => import('../views/login/components/new-phone/newPhone.vue')
            },
            {
                path: 'setPassword',
                name: 'setPassword',
                component: () => import('../views/login/components/set-password/setPassword.vue')
            },
            {
                path: 'verificationCode',
                name: 'verificationCode',
                component: () => import('../views/login/components/verification-code/verificationCode.vue')
            }
        ]
    },
    {
        path: '/personalInfo',
        name: 'personalInfo',
        component: () => import('../views/personal-info/personlInfo.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/home.vue')
    },
    {
        path: '/agreementFile',
        name: 'agreementFile',
        component: () => import('../views/agreementFile/agreementFile.vue')
    },
    {
        path: '/loading',
        name: 'loading',
        component: () => import('../views/loading/loading.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
