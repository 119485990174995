import API from './index.js'
import store from '../store'
import router from '../router'

let _resolve

const loadPromise = new Promise(resolve => {
    _resolve = resolve
})

async function loadOpenId(appName, code, redirectUrl) {
    sessionStorage.setItem('code', code)
    const res = await API.getOpenId({ appName, code })
    if (res.data && res.data.errorCode === 200) {
        const { wxUserInfo, hasAccount, userInfo, wxTokenID } = res.data.data
        store.commit('login/updateWxUserInfo', wxUserInfo)
        store.commit('login/updateLoginStatus', hasAccount)
        store.commit('login/updateUserInfo', userInfo)
        store.commit('login/updateAccessToken', userInfo.accessToken)
        store.commit('login/updateWxTokenID', wxTokenID)

        localStorage.setItem('yt_h5_accesstoken', userInfo.accessToken)

        setTimeout(() => {
            const { mobile, accessToken } = userInfo
            if (hasAccount && mobile && accessToken) {
                if (redirectUrl) {
                    if (redirectUrl.includes('?')) {
                        window.location.href = `${decodeURIComponent(redirectUrl)}&token=${accessToken}`
                    } else {
                        window.location.href = `${decodeURIComponent(redirectUrl)}?token=${accessToken}`
                    }
                } else {
                    // 进入官网首页 对应routeName
                    router.push({
                        name: 'home'
                    })
                }
            } else {
                router.push({
                    name: 'bindPhone'
                })
            }
        }, 100)

        _resolve(wxTokenID)
    } else {
        router.push({
            name: 'phoneLogin'
        })
        console.error('获取unionid失败')
    }

    return loadPromise
}

export default loadPromise

export { loadOpenId }
