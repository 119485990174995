import axios from 'axios'
import Config from '../config/env.js'
// import md5 from 'js-md5'
import Vue from 'vue'
import store from '@/store'

import { Toast } from 'vant'
Vue.use(Toast)

const myHttp = axios.create({
    baseURL: Config.apiPath,
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
    // withCredentials: true //解决跨域
})

// 添加请求拦截器
myHttp.interceptors.request.use(
    function(config) {
        const data = config.data || {}
        // console.log(Object.prototype.toString.call(data))

        // 在发送请求之前做些什么
        if (data.iExport === 1) {
            // console.log('下载的');
            // 下载文件操作
            config.responseType = 'blob'
        }

        // 判断是否存在token，如果存在则每个http header都加上token
        const token = store.state.login.accessToken
        // console.log('token: ', token, process.env.NODE_ENV)
        // 请求拦截器处理
        token && process.env.NODE_ENV !== 'development' && (config.headers['X-TOKEN'] = token)

        return config
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
myHttp.interceptors.response.use(
    function(response) {
        // token过期
        // console.log("response", response);
        if (response && response.data && response.data.errorCode !== 200) {
            let msg = response.data.errorMsg
            if (msg) {
                if (msg.includes('发送上限')) {
                    msg = '验证码超出发送上限, \n 请使用密码登录'
                }

                if (msg === 'Fail') {
                    msg = ''
                }
            }
            msg && Toast(msg)
        }

        return response
    },
    function(error) {
        // 对响应错误做点什么
        if (error.response.status === 401) {
            const data = {
                errroCode: -1,
                message: 'token 失效'
            }
            return Promise.resolve(data)
        }

        return Promise.reject(error)
    }
)

export default class MYHTTP {
    static get({ url = '', params = {}, config = {} }) {
        return myHttp.get(
            url,
            Object.assign(
                {},
                {
                    params: params
                },
                config
            )
        )
    }

    static post({ url = '', params = {}, config = {} }) {
        return myHttp.post(url, params, config)
    }

    static delete({ url = '', params = {}, config = {} }) {
        return myHttp.delete(url, params, config)
    }

    static patch({ url = '', params = {}, config = {} }) {
        return myHttp.patch(url, params, config)
    }

    static put({ url = '', params = {}, config = {} }) {
        return myHttp.put(url, params, config)
    }
}
