import moment from 'moment'

export default {
    // 是否mac系统
    isMac() {
        return navigator.platform.includes('Mac')
    },

    myBrowser() {
        const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
        const isOpera = userAgent.indexOf('Opera') > -1
        if (isOpera) {
            // 判断是否Opera浏览器
            return 'Opera'
        }
        if (userAgent.indexOf('Firefox') > -1) {
            // 判断是否Firefox浏览器
            return 'FF'
        }
        if (userAgent.indexOf('Chrome') > -1) {
            return 'Chrome'
        }
        if (userAgent.indexOf('Safari') > -1) {
            // 判断是否Safari浏览器
            return 'Safari'
        }
        if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
            // 判断是否IE浏览器
            return 'IE'
        }
    },

    getType(obj) {
        const toString = Object.prototype.toString
        const map = {
            '[object Boolean]': 'boolean',
            '[object Number]': 'number',
            '[object String]': 'string',
            '[object Function]': 'function',
            '[object Array]': 'array',
            '[object Date]': 'date',
            '[object RegExp]': 'regExp',
            '[object Undefined]': 'undefined',
            '[object Null]': 'null',
            '[object Object]': 'object'
        }
        if (obj instanceof Element) {
            return 'element'
        }
        return map[toString.call(obj)]
    },

    deepClone(data) {
        const type = this.getType(data)
        let obj
        if (type === 'array') {
            obj = []
        } else if (type === 'object') {
            obj = {}
        } else {
            // 不再具有下一层次
            return data
        }
        if (type === 'array') {
            for (let i = 0, len = data.length; i < len; i++) {
                obj.push(this.deepClone(data[i]))
            }
        } else if (type === 'object') {
            for (const key in data) {
                obj[key] = this.deepClone(data[key])
            }
        }
        return obj
    },

    sortByObjKey(list, key, desc = true) {
        // 默认降序
        function sortNumber(a, b) {
            if (desc) {
                return b[key] - a[key]
            } else {
                return a[key] - b[key]
            }
        }
        return list.sort(sortNumber)
    },

    // 输入文本去除特殊字符
    replaceSpecialCharts(val) {
        return val.replace(/[`～*~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '')
    },

    formatDateTime(date, fmt = 'yyyy-MM-dd HH:mm:ss') {
        if (typeof date === 'number') date = new Date(date)
        const o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'H+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            S: date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
            }
        }
        return fmt
    },

    deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj))
    },

    deepAssign(obj1, obj2) {
        let key
        const _res = this.deepCopy(obj1)
        for (key in obj2) {
            // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
            _res[key] = _res[key] && _res[key].toString() === '[object Object]' ? this.deepAssign(_res[key], obj2[key]) : (_res[key] = obj2[key])
        }
        return _res
    },

    parseUrl(url) {
        const praStr = url.split('?')[1]
        if (!praStr) {
            return {}
        }
        const praArr = praStr.split('&')
        const res = {}
        for (let i = 0; i < praArr.length; i += 1) {
            const arr = praArr[i].split('=')
            const [key, value] = arr
            res[key] = value
        }

        return res
    },

    checkPhone(phone) {
        return /^1[34578]\d{9}$/.test(phone)
    },

    // 获取最近N天的连续开始结束时间
    getContinueAnyDays(n) {
        const date = []
        for (; n > 0; n--) {
            date.push(
                moment()
                    .subtract(n, 'days')
                    .format('YYYY-MM-DD')
            )
        }
        return date
    },

    // 获取最近30天的开始结束时间
    getLast30Days() {
        const date = []
        date.push(
            moment()
                .subtract(30, 'days')
                .format('YYYY-MM-DD')
        )
        date.push(
            moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
        )
        return date
    },

    // 获取今天之前某一天
    getDaysAgo(n) {
        const date = []
        date.push(
            moment()
                .subtract(n, 'days')
                .format('YYYY-MM-DD')
        )
        date.push(
            moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
        )
        return date
    },

    // 获取时间戳对应的当天时间
    getDayByParam(d, type = 'and') {
        let format = ''
        if (type === 'and') {
            format = 'YYYY-MM-DD'
        } else if (type === 'ios') {
            format = 'YYYY/MM/DD'
        }
        return moment(d).format(format)
    },

    // 获取时间日月
    getMonthAndDayByParam(d, type = 'and') {
        let format = ''
        if (type === 'and') {
            format = 'MM-DD'
        } else if (type === 'ios') {
            format = 'MM/DD'
        }
        return moment(d).format(format)
    },

    getElementTop(element) {
        let actualTop = element.offsetTop
        let current = element.offsetParent

        while (current !== null) {
            actualTop += current.offsetTop
            current = current.offsetParent
        }
        return actualTop
    },

    addClass(obj, cls) {
        const objClass = obj.className, //获取 class 内容.
            blank = objClass != '' ? ' ' : '' //判断获取到的 class 是否为空, 如果不为空在前面加个'空格'.
        const added = objClass + blank + cls //组合原来的 class 和需要添加的 class.
        obj.className = added //替换原来的 class.
    },
    /** dom removeClass */
    removeClass(obj, cls) {
        let objClass = ' ' + obj.className + ' ' //获取 class 内容, 并在首尾各加一个空格. ex) 'abc    bcd' -> ' abc    bcd '
        objClass = objClass.replace(/(\s+)/gi, ' ') //将多余的空字符替换成一个空格. ex) ' abc    bcd ' -> ' abc bcd '
        let removed = objClass.replace(' ' + cls + ' ', ' ') //在原来的 class 替换掉首尾加了空格的 class. ex) ' abc bcd ' -> 'bcd '
        removed = removed.replace(/(^\s+)|(\s+$)/g, '') //去掉首尾空格. ex) 'bcd ' -> 'bcd'
        obj.className = removed //替换原来的 class.
    },

    /** dom hasClass */
    hasClass(obj, cls) {
        const objClass = obj.className, //获取 class 内容.
            objClassList = objClass.split(/\s+/) //通过split空字符将cls转换成数组.
        let x = 0
        for (x in objClass) {
            if (objClassList[x] == cls) {
                //循环数组, 判断是否包含cls
                return true
            }
        }
        return false
    }
}
