import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const dataState = createPersistedState({
    storage: window.sessionStorage,
    paths: [
        'login.hasAccount',
        'login.wxUserInfo',
        'login.userInfo',
        'login.isWxBrower', // 是否在微信浏览器内
        'login.wxTokenID',
        'login.redirectUrl', // 回调链接
        'login.accessToken', // 账号登录对应accesstoken = userInfo.accessToken
        'login.canPhoneBindWechat', // 用户输入的手机号是否可绑定当前微信 wxTokenID
        'login.inputPhone', // 用户登陆时输入的手机号
        'login.isPersonalBind', // 是否为个人信息页面绑定微信
        'login.timePhone', // 获取验证码的手机
        'login.timeAction', // 验证码的类型
        'login.timeNumber', // 获取验证码倒计时时间
        'login.timeSmsCode' // 验证码
    ]
})

export default new Vuex.Store({
    plugins: [dataState],
    modules: {
        login
    }
})
