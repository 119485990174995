/* eslint-disable */
import axios from './request.js'

export default class API {
    /**
     * 获取验证码
     * @param {
     *   mobile: 手机号
     *   action: 1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机
     * } params
     */
    static sendForYzm(params) {
        return axios.get({
            url: '/account/sms-code',
            params
        })
    }

    /**
     * 检测验证码是否正确
     * @param {
     *   mobile: 手机号
     *   action: 1 注册 2 登录 3 重置密码 4 解绑手机 5 绑定手机
     *   smsCode: 验证码
     * } params
     */
    static checkYzm(params) {
        const { mobile, action, smsCode } = params
        return axios.get({
            url: '/account/sms-code/status',
            params: {
                mobile,
                action,
                sms_code: smsCode
            }
        })
    }

    /**
     * 手机验证码登录
     * @param {
     *   phone: 手机号
     *   smsCode: 验证码
     * } params
     */
    static loginByPhoneSmsCode(params) {
        const { phone, smsCode, tokenID } = params
        return axios.post({
            url: '/account/user/login-sms-code',
            params: {
                mobile: phone,
                sms_code: smsCode,
                tokenID: tokenID || 1
            }
        })
    }

    /**
     * 手机验证码换绑验证
     * @param {
     *   phone: 手机号
     *   smsCode: 验证码
     * } params
     */
    static bindPhoneCheck(params) {
        const { phone, smsCode, tokenID } = params
        return axios.get({
            url: '/account/user/mobile',
            params: {
                mobile: phone,
                code: smsCode
            }
        })
    }

    /**
     * 手机验证码换绑
     * @param {
     *   phone: 手机号
     *   smsCode: 验证码
     * } params
     */
    static bindPhone(params) {
        const { phone, smsCode } = params
        return axios.post({
            url: '/account/user/mobile',
            params: {
                mobile: phone,
                code: smsCode
            }
        })
    }

    /**
     * 手机账号登录
     * @param {
     *   phone: 手机号
     *   password: 密码
     * } params
     */
    static loginByPhonePassword(params) {
        const { phone, password } = params
        return axios.post({
            url: '/account/user/login',
            params: {
                mobile: phone,
                password
            }
        })
    }

    /**
     * 绑定账号
     * @param {
     *   phone: 手机号
     *   password: 密码
     *   wxTokenID: 账号绑定加密token
     * } params
     */
    static bindAccount(params) {
        const { phone, password, wxTokenID } = params
        return axios.post({
            url: '/account/user/login',
            params: {
                mobile: phone,
                password,
                tokenID: wxTokenID
            }
        })
    }

    /**
     * 手机注册
     * @param {
     *    phone: 手机号
     *    smsCode: 验证码
     *    password: 密码
     *    wxTokenID: wxTokenID
     * } params
     */
    static register(params) {
        const { phone, smsCode, password, tokenID } = params
        return axios.post({
            url: '/account/user/register',
            params: {
                mobile: phone,
                sms_code: smsCode,
                password: password,
                proxy: 1,
                tokenID: tokenID || 1
            }
        })
    }

    /**
     * 获取unionid
     * @param {
     *   code: 换取uuid用
     *   appid: 公众号ID
     * } params
     */
    static getOpenId(params) {
        const { code, appName } = params
        return axios.get({
            url: '/h5/account/user/wx_user_info',
            params: {
                app_name: appName,
                code
            }
        })
    }

    /**
     * 重置密码-yzm
     * @param {
     *   "phone": "13717561643",
     *   "smsCode": "123456",
     *   "password": "abc12345678"
     * } params
     */
    static resetPassword(params) {
        const { phone, smsCode, password } = params
        return axios.post({
            url: '/account/user/rest-password',
            params: {
                mobile: phone,
                sms_code: smsCode,
                new_password: password
            }
        })
    }

    /**
     * 重置密码-old password
     * @param {
     *   "oldPassword": "老密码",
     *   "password": "新密码"
     * } params
     */
    static resetPasswordByOld(params) {
        const { oldPassword, password } = params
        return axios.patch({
            url: '/account/user/password',
            params: {
                oldPassword,
                password
            }
        })
    }

    /**
     * 修改个人信息： 这个接口需要登录X-TOKEN
     * @param {
     *   "avatar": "头像",
     *   "company": "企业",
     *   "mail": "邮箱"
     *   "mobile": "手机"
     *   "nick_name": "昵称"
     *   "union_id": "微信unionid"
     *   "user_id": "用户个人id"
     * } params
     */
    static changeBaseInfo(params) {
        const { avatar, company, mail, mobile, nick_name, union_id, user_id } = params
        let _params = {}
        if (avatar || company || mail || mobile || nick_name || union_id || user_id) {
            _params = {
                avatar,
                company,
                mail,
                mobile,
                nick_name,
                union_id,
                user_id
            }
        }
        return axios.put({
            url: '/account/user/base',
            params: _params
        })
    }

    /**
     * 获取个人信息： 这个接口需要登录X-TOKEN
     * @param {
     * } params
     */
    static getBaseInfo() {
        return axios.get({
            url: '/account/user/base'
        })
    }

    /**
     * 取消绑定微信
     * @param {
     * } params
     */
    static unBindWechat() {
        return axios.delete({
            url: '/account/user/wechat_bind'
        })
    }

    /**
     * 绑定微信
     * @param {
     *      "union_id": 微信id
     * } params
     */
    static bindWechat(params) {
        const { code, appName } = params
        return axios.post({
            url: '/h5/account/user/wechat_bind',
            params: {
                code,
                app_name: appName
            }
        })
    }

    /**
     * 检测手机号是否账号
     * @param {
     *      "phone": 手机号
     * } params
     */
    static checkPhoneStatus(params) {
        const { phone } = params
        return axios.get({
            url: '/account/user/mobile/status',
            params: {
                mobile: phone
            }
        })
    }

    /**
     * 检测token是否失效
     * @param {
     *      "accessToken":
     * } params
     */
    static checkTokenStatus(params) {
        const { accessToken } = params
        return axios.get({
            url: '/account/user/login-cric-data',
            params: {
                tk: accessToken
            }
        })
    }
}
